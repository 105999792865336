.navigation {
  background-color: transparent;
  height: 80px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    height: 100px;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 64px;
  }

  &-button {
    border: none;
    background: none;
    width: 28px;
    min-width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    position: relative;
    z-index: 20;
  }
}

.navigation-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  width: 100%;
  height: 80px;

  &-logo {
    width: 100%;
    height: 60px;
    position: relative;
    z-index: 20;
  }

  &-spacer {
    width: 28px;
  }

  @media (min-width: 414px) {
    &-logo {
      height: 70px;
    }
  }

  @media (min-width: 768px) {
    display: none;
  }
}

.navigation-desktop {
  display: none;
}
@media (min-width: 768px) {
  .navigation-desktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    width: 100%;

    &__left {
      display: flex;
      align-items: center;
    }

    &__navigation {
      display: flex;
      font-size: 16px;
      margin-left: 12px;
      margin-bottom: 0;

      & li {
        margin-right: 6px;

        &.active {
          a {
            font-weight: 700;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        a {
          text-decoration: none;
          color: #fff;
          font-size: 16px;
          padding: 12px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  background: linear-gradient(233.91deg, #1d2671 -1.81%, #c33764 95.34%);
  padding: 124px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  z-index: 10;

  &__navigation {
    li {
      margin-bottom: 28px;

      &.active {
        a {
          font-weight: 700;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      a {
        text-decoration: none;
        color: #fff;
        font-size: 16px;
      }
    }

    &.language-picker {
      li {
        a {
          text-transform: capitalize;

          &.active {
            font-weight: 700;
          }
        }
      }
    }
  }

  &__language {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 700;
    margin-bottom: 28px;

    img {
      margin-right: 8px;
    }
  }
}
