.page {
  &-card {
    padding: 24px 8px;
    max-width: 874px;
    width: 100%;
    margin: 0 auto;

    @media (min-width: 768px) {
      padding: 64px;

      h1 {
        text-align: center;
      }
    }

    blockquote {
      margin-left: 16px;
      padding: 16px;
      border-left: 2px solid #222;
      font-style: italic;
    }
  }
}
